<!--
 * @Description: 全部商品页面组件(包括全部商品,商品分类,商品搜索)
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-08 12:11:13
 -->
<template>
  <div class="goods" id="goods" name="goods">
    <!-- 面包屑 -->
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>
          <router-link to="/">首页</router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>全部商品</el-breadcrumb-item>
        <el-breadcrumb-item v-if="search">搜索</el-breadcrumb-item>
        <el-breadcrumb-item v-else>分类</el-breadcrumb-item>
        <el-breadcrumb-item v-if="search">{{ search }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 面包屑END -->

    <!-- 分类标签 -->
    <div style="width: 100%;background-color: #fff;">
      <div class="nav">
        <div class="product-nav">
          <div class="title">分类</div>
          <el-tabs v-model="activeName" type="card">
            <el-tab-pane v-for="item in categoryList" :key="item.Id" :label="item.Title" :name="'' + item.Id" />
          </el-tabs>

        </div>
      </div>
      <div class="nav" v-if="classifyIndex.length != 0">
        <div class="product-nav">
          <div class="title">分类</div>
          <el-tabs v-model="activeName2" type="card" @tab-click="handleClick">
            <el-tab-pane v-for="item in classifyIndex.ChildrenList" :key="item.Id" :label="item.Title"
              :name="'' + item.Id" />
          </el-tabs>

        </div>
      </div>
      <div class="nav flexspb" style="width: 1440px;margin: 0 auto 16px;" v-if="false">
        <div>
          <div class="product-nav" style="width: 900px;">
            <div class="title">价格</div>
            <el-tabs v-model="activeName2" type="card" @tab-click="handleClick">
              <el-tab-pane v-for="(item, index) in categoryList2" :key="index" :label="item" :name="'' + item" />
            </el-tabs>
          </div>
        </div>
        <div class="flexal">
          <div class="inp">
            <input type="number" style="width: 100%;height: 100%;" v-model="StartPrice" />
          </div>
          <div style="margin: 0 6px;">-</div>
          <div class="inp">
            <input type="number" style="width: 100%;height: 100%;" v-model="EndPrice" />
          </div>
          <div class="inp flexcen " style="margin-left: 6px;cursor: pointer;" v-on:click='bdjy()'>
            确定
          </div>
        </div>
      </div>
    </div>
    <!-- 分类标签END -->

    <!-- 主要内容区 -->
    <div class="main" style="display: flex;justify-content: space-between;">
      <div class="leftBox">
        <div class="tisl flexal">
          <h3>推荐热卖</h3>
        </div>
        <div class="tjhot-shoplist" id="ajax_hot_goods" style="padding-left: 4px;" v-for="(item, index) in hotGoods"
          :key="index">
          <router-link :to="{ path: '/goods/details', query: { productID: item.Id } }">
            <div class="alone-shop">
              <a><img class="lazy" :src="getUrl(item.ImgUrl)" /></a>
              <p class="line-two-hidd">
                <a>
                  {{ item.Name }}</a>
              </p>
              <p class="price-tag">
                <span class="li_xfo">￥</span><span>{{ item.Price }}</span>
              </p>
              <p class="store-alone">
                {{ item.ShopName }}
              </p>
            </div>
          </router-link>

        </div>
      </div>
      <div class="bigBox">
        <div class="sx_topb ba-dark-bg flexal">
          <div class="f-sort fl">
            <ul>
              <li class="" v-on:click="lis(index)" v-for="(item, index) in lists" :key="index" style="cursor: pointer;"
                :class="curr == index ? 'red' : ''">{{ item }}</li>
              <!-- <li class="">
                价格<i class="litt-zzx1"></i>
              </li> -->
              <!-- <li class="">
                <a href="/Home/Goods/goodsList/id/2/sort/sales_sum">销量</a>
              </li>
             
              <li class="">
                <a href="/Home/Goods/goodsList/id/2/sort/comment_count">评论</a>
              </li>
              <li class="">
                <a href="/Home/Goods/goodsList/id/2/sort/is_new">新品</a>
              </li> -->
            </ul>
          </div>
          <!--	<div class="f-address fl">
					<div class="shd_address">
						<div class="shd">收货地：</div>
						<div class="add_cj_p"><input type="text" id="city" /></div>
                        </ul>
					</div>
				</div>-->
          <!-- <div class="f-total fr">
            <div class="all-sec">共<span>2</span>个商品</div>
            <div class="all-fy">
              <a>&lt;</a>
              <p class="fy-y"><span class="z-cur">1</span>/<span>1</span></p>
              <a>&gt;</a>
            </div>
          </div> -->
        </div>
        <div class="list">
          <MyList :list="product" v-if="product.length > 0"></MyList>
          <div v-else class="none-product">
            抱歉没有找到相关的商品，请看看其他的商品
          </div>
        </div>
        <div class="pagination">
          <el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="total"
            @current-change="currentChange"></el-pagination>
        </div>
      </div>

      <!-- 分页 -->

      <!-- 分页END -->
    </div>
    <!-- 主要内容区END -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      categoryList: "", //分类列表
      categoryID: 0, // 分类id
      product: "", // 商品列表
      productList: "",
      total: 0, // 商品总量
      pageSize: 16, // 每页显示的商品数量
      currentPage: 1, //当前页码
      activeName: "-1", // 分类列表当前选中的id
      activeName2: "-1", // 分类列表当前选中的id
      search: "", // 搜索条件
      categoryList2: ["7360元", "14720元", "22080元", "22080元以上"],
      hotGoods: [],
      StartPrice: "", //起始价
      EndPrice: "", //结束价
      lists: ["综合", "销量", "价格升序", "价格降序"],
      curr: 0,
      prie: false,
      classifyIndex: []
    };
  },
  created() {
    // 获取分类列表
    this.getCategory();
  },
  activated() {
    // this.activeName = "0"; // 初始化分类列表当前选中的id为-1
    // this.activeName2 = "0"; // 初始化分类列表当前选中的id为-1
    this.total = 0; // 初始化商品总量为0
    this.currentPage = 1; //初始化当前页码为1
    console.log(this.$route.query);
    // 如果路由没有传递参数，默认为显示全部商品
    if (Object.keys(this.$route.query).length == 0 || this.$route.query.categoryID == null || this.$route.query.categoryID == undefined) {
      this.categoryID = null;
      this.activeName = "0";
      this.activeName2 = "0";
      return;
    }
    // 如果路由传递了categoryID，则显示对应的分类商品
    if (this.$route.query.categoryID != undefined) {
      this.categoryID = this.$route.query.categoryID;
      if (this.categoryID.length == 1) {
        this.activeName = "" + this.categoryID;
      }
      return;
    }
    // 如果路由传递了search，则为搜索，显示对应的分类商品
    if (this.$route.query.search != undefined) {
      this.search = this.$route.query.search;
    }
  },
  watch: {
    // 监听点击了哪个分类标签，通过修改分类id，响应相应的商品
    activeName: function (val) {
      console.log(val, "val")
      this.categoryID = val;
      this.classifyIndex = []
      if (val != 0) {
        this.classifyIndex = []
        let list = this.categoryList.filter((v, index) => {
          return v.Id == val
        })
        if (list.length != 0) {
          const val2 = {
            Id: val,
            Title: "全部",
          };

          this.classifyIndex = list[0]
          if (this.classifyIndex.ChildrenList[0].Id != val) {
            this.classifyIndex.ChildrenList.splice(0, 0, val2)
          }

        }

      }
      if (val == 0) this.categoryID = null;
      // 初始化商品总量和当前页码
      this.total = 0;
      this.currentPage = 1;
      // 更新地址栏链接，方便刷新页面可以回到原来的页面
      this.getData()
      // this.$router.push({
      //   path: "/goods",
      //   query: {
      //     categoryID: this.categoryID,
      //   },
      // });
    },
    // 监听搜索条件，响应相应的商品
    search: function (val) {
      if (val != "") {
        this.getData(val);
      }
    },
    // 监听分类id，响应相应的商品
    categoryID: function () {
      this.getData();
      this.search = "";
    },
    // 监听路由变化，更新路由传递了搜索条件
    $route: function (val) {
      if (val.path == "/goods") {
        if (val.query.search != undefined) {
          this.activeName = "-1";
          this.currentPage = 1;
          this.total = 0;
          this.search = val.query.search;
        }
        if (val.query.categoryID) {
          this.categoryID = val.query.categoryID;
          this.activeName = "" + this.categoryID;
        } else {
          this.categoryID = null;
          this.activeName = "0";
        }
        this.getData();
      }
    },
  },
  methods: {
    // 返回顶部
    backtop() {
      const timer = setInterval(function () {
        const top =
          document.documentElement.scrollTop || document.body.scrollTop;
        const speed = Math.floor(-top / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          top + speed;

        if (top === 0) {
          clearInterval(timer);
        }
      }, 20);
    },
    // 页码变化调用currentChange方法
    currentChange(currentPage) {
      this.currentPage = currentPage;
      this.getData();
      this.backtop();
    },
    // 向后端请求分类列表数据
    async getCategory() {
      var res = await this.postdata("/News/ClassList", {
        "page": 1, //分页页码，数字类型
        "limit": 1000, //条数
        "queryState": 0, //状态 0-可用
        "queryType": 0,//类型 0-商品
        parentId: 0
      });
      if (res.code == 200) {
        const val = {
          Id: 0,
          Title: "全部",
        };
        const cate = res.data.items;
        cate.unshift(val);
        this.categoryList = cate;
      }
    },
    // 向后端请求全部商品或分类商品数据
    async getData() {
      // 如果分类列表为空则请求全部商品数据，否则请求分类商品数据
      const res = await this.postdata("/Goods/GoodsList", {
        page: this.currentPage,
        limit: this.pageSize,
        parentId: this.categoryID,
        queryName: this.search,
        // "StartPrice": this.StartPrice, //起始价
        // "EndPrice": this.EndPrice ,//结束价
        orderBy: this.curr,
        "queryType": 0
      });
      if (res.code == 200) {
        this.product = res.data.items;
        this.product.map(v => {
          v.num = 1
          v.tempIndex = 0
        })
        this.total = res.data.total;
        console.log(this.product)
      }
      let res2 = await this.postdata("/Goods/GoodsList", {
        page: 1,
        limit: 4,
        // parentId: this.categoryID,
        queryName: this.search,
        "IsHot": 1, //热门 1-是
        "queryType": 0
      })
      if (res.code == 200) {
        this.hotGoods = res2.data.items;
      }
    },
    async handleClick(tab, event) {
      console.log(tab, event);
      const res = await this.postdata("/Goods/GoodsList", {
        page: this.currentPage,
        limit: this.pageSize,
        parentId: tab.name,
        queryName: this.search,
        // "StartPrice": this.StartPrice, //起始价
        // "EndPrice": this.EndPrice ,//结束价
        orderBy: this.curr,
        "queryType": 0
      });
      if (res.code == 200) {
        this.product = res.data.items;
        this.product.map(v => {
          v.num = 1
          v.tempIndex = 0
        })
        this.total = res.data.total;
        console.log(this.product)
      }
      // if (tab.index == 3) {
      //   this.StartPrice = tab.label.substring(0, 5)
      //   this.EndPrice = ''
      // } else {
      //   this.StartPrice = ''
      //   this.EndPrice = tab.label.substring(0, tab.label.length - 1)
      // }

      // this.getData();
    },
    lis(index) {
      this.curr = index
      this.getData()
    },
    bdjy() {
      this.curr = 0
      this.getData()
    }
  },
};
</script>

<style scoped>
.goods {
  background-color: #fff;
}

/* 面包屑CSS */
.el-tabs--card .el-tabs__header {
  border-bottom: none;
}

.goods .breadcrumb {
  height: 50px;
  background-color: white;
  padding-top: 16px;
}

.goods .breadcrumb .el-breadcrumb {
  width: 1440px;
  line-height: 30px;
  font-size: 16px;
  margin: 0 auto;
}

/* 面包屑CSS END */

/* 分类标签CSS */
.goods .nav {
  background-color: white;
  margin-bottom: 16px;
}

.goods .nav .product-nav {
  width: 1440px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
}

.nav .product-nav .title {
  width: 50px;
  font-size: 16px;
  font-weight: 700;
  float: left;
}

/* 分类标签CSS END */

/* 主要内容区CSS */
.goods .main {
  margin: 0 auto;
  max-width: 1290px;
}

.goods .main .list {
  min-height: 650px;
  padding-top: 14.5px;
  margin-left: -13.7px;
  overflow: auto;
}

.goods .main .pagination {
  height: 50px;
  text-align: center;
}

.goods .main .none-product {
  color: #333;
  margin-left: 13.7px;
}

/* 主要内容区CSS END */
.inp {
  width: 50px;
  height: 30px;
  border: 1px solid#f4f4f4;
  padding: 0 6px;
}

.bigBox {
  width: 1090px;
  border: 1px solid#f4f4f4;
}

.sx_topb {
  height: 40px;
  width: 100%;
  background: #f7f7f7;
  line-height: 40px;
  overflow: hidden;
  padding-left: 4px;
  box-sizing: border-box;
}

.sx_topb .f-sort ul li {
  float: left;
  width: 62px;
  height: 26px;
  border: 1px solid #cfcfcf;
  line-height: 26px;
  margin-right: -1px;
  background: #fff;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}

.sx_topb .f-sort ul li i {
  display: inline-block;
  margin-left: 3px;
}

.sx_topb .f-sort ul li:hover {
  border-color: #e23435;
  position: relative;
}

.sx_topb .f-sort ul .red,
.sx_topb .f-sort ul .ls-dlzc a:hover,
.ls-dlzc .sx_topb .f-sort ul a:hover,
.sx_topb .f-sort .top-ri-header ul li:hover,
.top-ri-header .sx_topb .f-sort ul li:hover,
.sx_topb .f-sort ul .conta-hv-nav li:hover a,
.conta-hv-nav li:hover .sx_topb .f-sort ul a {
  background-color: #e23435;
  border-color: #e23435;
  color: #fff;
}

.sx_topb .f-sort ul .red a,
.sx_topb .f-sort ul .ls-dlzc a:hover a,
.ls-dlzc .sx_topb .f-sort ul a:hover a,
.sx_topb .f-sort .top-ri-header ul li:hover a,
.top-ri-header .sx_topb .f-sort ul li:hover a,
.sx_topb .f-sort ul .conta-hv-nav li:hover a a,
.conta-hv-nav li:hover .sx_topb .f-sort ul a a {
  color: white;
}

.leftBox {
  width: 190px;
  border: 1px solid #0C6A6A;
}

.tisl {
  height: 40px;
  /* width: 100%; */
  background: #0C6A6A;
  padding-left: 4px;
  color: #fff;
}

.alone-shop {
  padding-top: 20px
}

.alone-shop img {
  width: 180px;
  height: 180px;
  margin: 0 auto
}

.line-two-hidd {
  font-family: "微软雅黑";
  font-size: 14px;
  overflow: hidden;
  width: 100%;
  height: 42px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.price-tag {
  font-size: 18px;
  color: #e23435;
  padding: 5px 0
}

.store-alone a {
  font-size: 12px;
  color: #999999
}

.store-alone a:hover {
  color: #e23435
}
</style>
